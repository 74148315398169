import React, { useState, useEffect } from "react";
import useSound from "use-sound";
import { RichText } from "prismic-reactjs";

import Container from "../Container";
import BasePadding from "../BasePadding";
import PlayButton from "../PlayButton";

import styles from "./AudioPlayer.module.scss";
import Eyebrow from "../Eyebrow";

const Podcast = ({ audio, setItem, currentItem, backgroundColor, index }) => {
	const [play, { stop, isPlaying }] = useSound(audio.audio_file.url);
	
	useEffect(() => {
		if (currentItem !== index && isPlaying) {
			stop();
		}
	}, [currentItem]);

	const playClip = () => {
		setItem(index);
		play();
	};

	const stopClip = () => {
		stop();
	};

	return (
		<div className={currentItem === index ? styles.podcastOpen : styles.podcastClosed}>
			<div className={styles.podcastTop}>
				<PlayButton
					icon
					active={isPlaying}
					play={index => playClip(index)}
					stop={stopClip}
					backgroundColor={backgroundColor}
				/>
				<button className={styles.titleButton} onClick={() => setItem(index)}><span className={isPlaying ? styles.titleIsPlaying : styles.title}>{audio.podcast_title}</span></button>
				<button className={currentItem === index ? styles.collapse : styles.expand} onClick={() => setItem(index)}>{currentItem === index ? "Collapse" : "Expand"}</button>
			</div>
			<div className={styles.podcastBottom}>
				<RichText render={audio.podcast_description} />
			</div>
		</div>
	)
}

const AudioPlayer = ({ addPadding, content: { primary, items }}) => {
	const [ imgObject, setImgObject ] = useState(items[0]?.graphic);
	const [ activeItem, setActiveItem ] = useState(items[0]);
	const setItem = index => {
		setActiveItem(index);
		setImgObject(items[index].graphic ? items[index].graphic: "");
	}

	return (
		<div className={`
			${styles.audioPlayer}
			${addPadding ? styles.paddingTop : ""}
			${primary.background_color ? styles[`background${primary.background_color}`] : ""}	
		`}>
			<Container>
				<BasePadding>
					{primary.eyebrow &&
						<div className={styles.eyebrow}>
							<Eyebrow eyebrow={primary.eyebrow} />
						</div>
					}
					<div className={styles.audioContainer}>
						<div className={styles.description}>
							{imgObject && <img src={imgObject.url} alt={imgObject.alt} />}
						</div>
						<div className={styles.podcasts}>
							{items.map((item, index) => (
								<div key={index} className={styles.podcast}>
									<Podcast
										index={index}
										currentItem={activeItem}
										audio={item}
										setItem={index => setItem(index)}
										backgroundColor={primary.background_color}
									/>
								</div>
							))}
						</div>
					</div>
				</BasePadding>
			</Container>
		</div>
	)
};

export default AudioPlayer;