import React from "react";
import qs from 'qs';
import Cta from "../Cta";
import BasePadding from "../BasePadding";
import Container from "../Container";
import styles from '../Form/Form.module.scss';

const GatedContent = ({ addPadding, content: { primary }, location}) => {
    const params = qs.parse(location.search.slice(1));
	return (
		<div className={`
			${styles.slimForm}
			${addPadding ? styles.paddingTop : ""}
			${primary.background_color ? styles[`background${primary.background_color}`] : ""}
		`}>
			<Container>
				<BasePadding>
					{Object.keys(params).includes("redirect") ? (
						<>
							<h4>Thanks! Now let's download that white paper.</h4>
							<Cta type="button">
								<a href={primary?.pdf?.url} target="_blank" rel="noopener noreferrer" 	type="download">Download White Paper</a>
							</Cta>
						</>
					) : (
						<form action="https://usebasin.com/f/b00b5b6094a4" method="POST">
							<div className={`${styles.input} ${styles.firstInput}`}>
								<input type="text" id="name" placeholder="FULL NAME*" name="name" required="required" />
							</div>
							<div className={styles.input}>
								<input type="email" id="email" placeholder="EMAIL ADDRESS*" name="email" required="required" />
							</div>
							<div className={styles.input}>
								<input type="text" id="company" placeholder="COMPANY NAME*" name="company" required="required" />
							</div>
							<div className={styles.input}>
								<input type="text" id="title" placeholder="JOB TITLE" name="company" />
							</div>
							<div className={styles.input}>
								<input type="text" id="budget" placeholder="CURRENT ANNUAL AUDIO BUDGET" name="budget" />
							</div>
							<div className={styles.input}>
								<textarea id="how-did-you-hear-about-us"  placeholder="HOW DID YOU HEAR ABOUT US?" name="how-did-you-hear-about-us" required="required" />
							</div>
							<Cta type="button">
								<input type="submit" />
							</Cta>
						</form>
					)}
				</BasePadding>
			</Container>
		</div>
	);
};

export default GatedContent;
