import React, { useRef, useState, useEffect } from "react";
import { RichText } from "prismic-reactjs";
import { Link } from "react-router-dom";

import styles from "./LogoSlider1.module.scss";
import Container from "../Container";
import BasePadding from "../BasePadding";
import Cta from "../Cta";
import Eyebrow from "../Eyebrow";

const LogoSlider = ({ addPadding, content: { primary, items }}) => {
	const [ displaySlides, setDisplaySlides ] = useState([]);
	const [ isTransitioning, setIsTransitioning ] = useState(0);
	const timer = useRef();

	useEffect(() => {
		setDisplaySlides(items.slice(0, 5));
	}, [items]);

	useEffect(() => {
		timer.current = setInterval(() => {
			const displayArray = [...displaySlides];
			const itemsNotInActiveArray = items.filter(item => !displayArray.includes(item));
			const randomNewItem = itemsNotInActiveArray[Math.floor(Math.random() * itemsNotInActiveArray.length)];
			const randomReplaceItem = Math.floor(Math.random() * displayArray.length);
			displayArray.splice(randomReplaceItem, 1, randomNewItem);
			setIsTransitioning(randomReplaceItem);
			setTimeout(() => {
				setIsTransitioning(null);
			}, 750);
			setDisplaySlides(displayArray);
		}, 2500);
		return () => {
			clearInterval(timer.current);
		};
	}, [displaySlides]);

	return (
		<div className={`
			${styles.logoSlider}
			${addPadding ? styles.paddingTop : ""}
			${primary.background_color ? styles[`background${primary.background_color}`] : ""}
		`}>
			<Container>
				<BasePadding>
					<div className={`${styles.content} ${styles.logosOnly}`}>
						<div className={styles.copy}>
							{primary?.eyebrow  && <Eyebrow eyebrow={primary.eyebrow[0]?.text} />}
							<div className={styles.text}>
								<RichText render={primary.text1} />
								{primary.cta_link?.slug &&
									<div className={styles.footer}>
										<Cta type="button" color={primary.background_color}>
											<Link to={`/${primary.cta_link.slug}`}>{primary.cta_title}</Link>
										</Cta>
									</div>
								}
								{primary.cta_link?.url &&
									<div className={styles.footer}>
										<Cta type="button" color={primary.background_color}>
											<a href={primary.cta_link.url}>{primary.cta_title}</a>
										</Cta>
									</div>
								}
							</div>
						</div>



						<div className={styles.logos}>
							{displaySlides.map((item, index) => (
								item?.link?.url ?
								<a href={item.link.url} className={`${styles.logo} ${isTransitioning === index ? styles.transitionIn : ""}`} key={index} target="_blank">
									<img src={item.logo.url} alt={item.logo.alt} />
								</a>
								:
								<div className={`${styles.logo} ${isTransitioning === index ? styles.transitionIn : ""}`} key={index}>
									{item && <img src={item.logo.url} alt={item.logo.alt} />}
								</div>
							))}
						</div>
					</div>

				</BasePadding>
			</Container>
		</div>
	);
};

export default LogoSlider;
