import React, { useEffect, useState, useRef, forwardRef } from "react";
import { Helmet } from "react-helmet";
import { InView } from "react-intersection-observer";
import { client } from "../../prismic-configuration";
import { withRouter } from "react-router-dom";
import ReactGA from 'react-ga4';

import NotFound from "../NotFound";

import Hero from "../../components/Hero";
import ComponentComposer from "../../components/ComponentComposer";
import SvgSquiggle from "../../components/SvgSquiggle";

import styles from "./Page.module.scss";
import NewsletterPopup from "../../components/NewsletterPopup";

const Page = ({ match, location }) => {
	const [ content, setContentData ] = useState(null);
	const [ globalData, setGlobalData ] = useState(null);
	const [ isLoading, setIsLoading ] = useState(true);
	const [ notFound, toggleNotFound ] = useState(false);
	const [ isHomepage, setIsHomepage ] = useState(false);
	const [ showPopup, setShowPopup ] = useState(false);
	const prevLocation = useRef();

	const uid = match.params.uid;

	useEffect(() => {
		const fetchData = async () => {
			// Resolve `/` routes to fetch the Prismic homepage
			const pageId = uid === "" ? "homepage" : uid;
			const result = await client.getByUID("page", pageId);
			setIsLoading(true);
			toggleNotFound(false);
			if (result) {
				// If we have data, setData
				setIsHomepage(uid === "");
				setIsLoading(false);
				setContentData(result.data);
			} else {
				// Otherwise show an error message
				console.warn("Page document not found. Make sure it exists.");
				setIsLoading(false);
				toggleNotFound(true);
			}
			if (location.hash) {
				document.getElementById(location.hash.substring(1)).scrollIntoView();
				return;
			}
			if (prevLocation.current !== location.pathname) {
				// scroll to the top of the page if the url is different
				window.scrollTo(0, 0);
				prevLocation.current = location.pathname;
			}
			ReactGA.send({ hitType: "pageview", page: document.location.pathname, title: document.title });
			return;
		};
		fetchData();
	}, [uid, location]); // Skip the Effect hook if the UID hasn't changed

	useEffect(() => {
		const fetchData = async () => {
			const result = await client.getSingle("globals");

			if (result) {
				setGlobalData(result.data);
			}

			return;
		}
		fetchData();
	}, []);

	// const scrolledOutOfView = () => {
	// 	// When scrolled past the hero, open the signup modal, unless it has already been dismissed
	// 	if (sessionStorage.getItem('modal') !== 'false' && globalData?.disable_modal_popup !== true) {
	// 		ReactGA.event({
	// 			category: "modal_popup",
	// 			action: "popup viewed",
	// 			label: "newsletter",
	// 		  });
	// 		setShowPopup(true);
	// 	} else {
	// 		setShowPopup(false);
	// 	}
	// };

	if (isLoading) {
		// Loading state
		return (
			<div className={styles.loading}><SvgSquiggle /></div>
		)
	}
	else if (content && !notFound) {
		// Content for this slug has returned, render data
		return (
			<>
				<Helmet>
					<title>{content.page_title === "Homepage" ? `Oxford Road` : `${content.page_title} | Oxford Road`}</title>
					<meta property="og:title" content={content.page_title} />
					<meta property="og:description" content={content.description ? content.description : ""} />
					<meta property="og:image" content={content.og_image?.url ? content.og_image?.url : ""} />
					<meta property="og:image" content={content.og_image?.url ? content.og_image?.url : ""} />
				</Helmet>
				{content.hero_background?.url &&
					<InView onChange={inView => setShowPopup(!inView)}>
						<Hero
							background={content.hero_background}
							fallbackImage={content.background_image}
							heroTitle={content.hero_title}
							text={content.text}
							cta_link={content.cta_link?.url}
							cta_text={content.cta_text}
							heroCarousel={content.text_carousel[0]?.carousel_title.length ? content.text_carousel : null}
							heroAlignment={content.text_alignment}
							showArrow={content.show_arrow}
							showBottomBorder={content.show_bottom_border}
							textColor={content.text_color}
							audio={content.audio}
							isHomepage={isHomepage}
							content_data={content}
						/>
					</InView>
				}
				{content?.body &&
					content.body.map((item, index) => {
						const previousItem = content.body[index - 1];
						return (
							<ComponentComposer
								key={index}
								previousComponent={previousItem}
								component={item} index={index}
								lastItem={index + 1 === content.body.length}
								location={location}
							/>
						)
					})
				}
				{globalData &&
					<NewsletterPopup globalData={globalData} showPopup={showPopup} />
				}
			</>
		)
	}
	else {
		// There is no content for this slug
		return <NotFound />
	}
};

export default withRouter(Page);
