// - Name
// - Email
// - Roles you are interested in (Drop Down Menu - Analytics, Account Management, Business Development, Creative, Finance, HR, Media)
// - Tell us about yourself (short text)
// - Resume ?

import React from "react";
import styles from "./Form.module.scss";
import Cta from "../Cta";
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/base/TextareaAutosize';

const Jobs = props => (
	<form action="https://usebasin.com/f/06814dcae69c" enctype="multipart/form-data" method="POST">
		<Stack spacing={1.5} mb={1} className={styles.inputWrapper}>
			<TextField type="text" id="name" name="name" label="NAME" required="required" />
			<TextField type="email" id="email" name="email" label="EMAIL ADDRESS" required="required" />
			<label htmlFor="roles">
				Role you are interested in
			</label>
			<select id="roles" name="roles">
				<option value="analytics">Analytics</option>
				<option value="account-management">Account Management</option>
				<option value="business-development">Business Development</option>
				<option value="creative">Creative</option>
				<option value="finance">Finance</option>
				<option value="hr">HR</option>
				<option value="media">Media</option>
			</select>
			<label for="description">Tell us about yourself</label>
			<TextareaAutosize id="description" name="description" required="required" />
			<label htmlFor="resume">
				Resume <span className={styles.asterisk} />
			</label>
			<input id="resume" type="file" name="resume" required="required" accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
			<Cta type="button" color={props.background_color}>
				<input type="submit" />
			</Cta>
		</Stack>
	</form>
);

export default Jobs;
