// Email

import React from "react";
import styles from "./Form.module.scss";
import Cta from "../Cta";
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

const Newsletter = props => (
	<form action="https://usebasin.com/f/4c73e4eeb1d1" method="POST">
		<Stack spacing={1.5} mb={1} className={styles.inputWrapper}>
			<TextField type="text" id="linkedin" name="linkedin" label="LINKEDIN" required="required" />
			<TextField type="email" id="email" name="email" label="EMAIL ADDRESS" required="required" />
			<Cta type="button" color={props.background_color}>
				<input type="submit" />
			</Cta>
		</Stack>
	</form>
);

export default Newsletter;
