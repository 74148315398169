import React, { useState } from "react";
import CountUp from "react-countup";
import { InView } from "react-intersection-observer";
import { RichText } from "prismic-reactjs";
import { Link } from "react-router-dom";

import BasePadding from "../BasePadding";
import Container from "../Container";

import styles from "./Stats.module.scss";
import Cta from "../Cta";

const Stats = ({ background, addPadding, content: { primary, items }}) => {

	const [ finishedCounting, setFinishedCounting ] = useState(false);

	const isFinished = () => {
		setFinishedCounting(true);
	};

	return (
		<div className={`
			${styles.stats}
			${addPadding ? styles.paddingTop : ""}
			${primary.background_color ? styles[`background${primary.background_color}`] : ""}
		`}>
			<Container>
				<BasePadding>
				<>
					<div className={`${styles.statsContainer} ${items.length === 1 ? styles.single : ""}`}>
	
						{items.map((item, index) => {
							return (
								<div key={index} className={styles.stat}>
									<div className={`${styles.number} ${item.number > 999 ? styles.largeNumber : ""} ${finishedCounting ? styles.finished : styles.running}`}>
											{item.number > 999
											?
											<>
												{item.number_prefix}
												<CountUp
													start={0}
													separator=","
													end={item.number}
													onEnd={() => isFinished()}
												>
													{({ countUpRef, start }) => (
														<InView onChange={inView => inView && start() } triggerOnce>
															<span ref={countUpRef} className={styles.bigNumber} />
														</InView>
													)}
												</CountUp>
												{/* <CountUp
													start={0}
													separator=","
													end={item.number}
												>
													{({ countUpRef, start }) => (
														<InView onChange={inView => inView && start() } triggerOnce>
															<span ref={countUpRef} className={styles.smallNumber} />
														</InView>
													)}
												</CountUp> */}
												{item.number_suffix}
											</>
										:
											<>
												{item.number_prefix}
												<CountUp
													start={0}
													end={item.number}
													onEnd={() => setFinishedCounting(true)}
												>
													{({ countUpRef, start }) => (
														<InView onChange={inView => inView && start() } triggerOnce>
															<span ref={countUpRef} />
														</InView>
													)}
												</CountUp>
												{item.number_suffix}
											</>
										}
									</div>
									<div className={styles.copy}>
										<hr />
										{item.stat_title &&
											<div className={styles.title}>
												<RichText render={item.stat_title} />
											</div>
										}
										{item.stat_text &&
											<div className={styles.text}>
												<RichText render={item.stat_text} />
											</div>
										}
									</div>
								</div>
							)
						})}
					</div>
					{primary.cta_link?.slug &&
						<div className={styles.footer}>
							<Cta type="button" color={primary.background_color}>
								<Link to={`/${primary.cta_link.slug}`}>{primary.cta_title}</Link>
							</Cta>
						</div>
					}
					{primary.cta_link?.url &&
						<div className={styles.footer}>
							<Cta type="button" color={primary.background_color}>
								<a href={primary.cta_link.url}>{primary.cta_title}</a>
							</Cta>
						</div>
					}
					</>
				</BasePadding>
			</Container>
		</div>
	);
};

export default Stats;
