import React from "react";
import { RichText } from "prismic-reactjs";
import { Link } from "react-router-dom";
import BasePadding from "../BasePadding";
import Container from "../Container";
import Eyebrow from "../Eyebrow";
import Cta from "../Cta";



import styles from "./TeamColumns.module.scss";




const SingleColumn = ({ eyebrow, text, image, hasImage }) => (
	<div className={hasImage ? styles.columnWithImage : styles.column}>
<div className={styles.image}><img src={image.url} /></div>

		<div className={styles.text}>
			<RichText render={text} />
		</div>

	</div>
);

const fileType = file => { return file ? file.match(/\.[0-9a-z]+$/i) : null };

const Columns = ({ addPadding, content: { primary, items }}) => {
	const mediaFileType = primary.media?.name ? fileType(primary.media.name) : null;
	return (
		<div className={`
			${styles.columns}
			${styles[`align${primary.align_text}`]}
			${styles.paddingTop}
			${primary.background_color ? styles[`background${primary.background_color}`] : ""}
			${primary.rounded_images ? styles.roundedImages : ""}
		`}>
			<Container>
				<BasePadding>
					{primary.eyebrow &&
						<div className={styles.eyebrow}>
							<Eyebrow eyebrow={primary.eyebrow} />
						</div>
					}

					<div className={`${primary.media?.url ? styles.wrapperWithImage : styles.wrapper} ${primary.number_of_columns ? styles.threeColumns : ""}`}>
						{primary.media?.url ?
							<>
								<SingleColumn hasImage eyebrow={items[0].eyebrow} text={items[0].text1} />
								<div className={styles.columnWithImage}>
									{mediaFileType && mediaFileType[0] === ".mp4"
									?
										<video autoPlay muted loop playsInline>
											<source src={primary.media.url} type="video/mp4" />
										</video>
									:
										<img src={primary.media.url} alt={primary.media.alt} />
									}
								</div>
							</>
							:
							<>
								{items?.map((item, index) => (
									<SingleColumn key={index} eyebrow={item.eyebrow} text={item.text1} image={item.image} />

								))}
							</>
						}
					</div>
				</BasePadding>
			</Container>
		</div>
	);
};

export default Columns;
