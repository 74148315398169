import React, { useEffect, useState } from "react";
import { client } from "../../prismic-configuration";
import Prismic from "prismic-javascript";
import moment from "moment";
import { RichText } from "prismic-reactjs";

import styles from "./Posts.module.scss";
import { Link, withRouter } from "react-router-dom";
import Eyebrow from "../Eyebrow";
import Cta from "../Cta";
import Container from "../Container";
import BasePadding from "../BasePadding";

const POSTLIMIT = 96;

const Posts = ({ match, addPadding, content: { primary }}) => {
	const [ postsData, setPostsData ] = useState([]);
	const [ currentPage, setCurrentPage ] = useState(1);
	const [ postCount, setPostCount ] = useState(4);
	const [ totalPosts, setTotalPosts ] = useState(0);
	const [ allLoaded, setIsAllLoaded ] = useState(false);
	const isBlogIndex = match.params.uid === "thought-leadership";
	const postType = primary.tag?.type;

	useEffect(() => {
		let posts = [];
		const fetchPosts = async () => {
			const findTag = primary.tag?.tags?.find(tag => tag !== "Thought Leadership");
			client.query([
				Prismic.Predicates.at('document.type', postType),
				Prismic.Predicates.any('document.tags', findTag ? [`${findTag.toLowerCase()}`, `${findTag}`] : []),
			], { pageSize: POSTLIMIT }).then(response => {
				posts = response.results;
				posts.sort((a, b) => (b.data?.post_date ? new Date(b.data?.post_date) : new Date(b.first_publication_date)) - (a.data?.post_date ? new Date(a.data?.post_date) : new Date(a.first_publication_date)));
				setTotalPosts(response.total_results_size);
				setIsAllLoaded(postCount >= response.total_results_size);
				setPostsData(posts);
			});
		};
		fetchPosts();
	}, []);

	// Because of how posts are fetched, and sorted by an authored post date, the load more functionality isn't working as expected.
	// Instead I'm loading in 96 posts (6 posts are displayed at a time), anything after that should really be an archive

	// const fetchMore = () => {
	// 	const existingPosts = [...postsData];
	// 	let posts = [];
	// 	const fetchPosts = async () => {
	// 		const findTag = primary.tag?.tags?.find(tag => tag !== "Thought Leadership");
	// 		client.query([
	// 			Prismic.Predicates.at('document.type', 'post'),
	// 			Prismic.Predicates.at('document.tags', findTag ? [findTag] : []),
	// 		], { pageSize: 96, page: currentPage }).then(response => {
	// 			posts = existingPosts.concat(response.results);
	// 			posts.sort((a, b) => (b.data?.post_date ? new Date(b.data?.post_date) : new Date(b.first_publication_date)) - (a.data?.post_date ? new Date(a.data?.post_date) : new Date(a.first_publication_date)));
	// 			setIsAllLoaded(response.total_results_size === posts.length);
	// 			setPostsData(posts);
	// 		});
	// 	};
	// 	fetchPosts();
	// };

	// useEffect(() => {
	// 	if (currentPage !== 1) {
	// 		fetchMore();
	// 	}
	// }, [currentPage]);

	const displayMore = () => {
		// if (postCount < postsData.length) {
			// Loading in 18 posts to start, sorting them. Since 6 are displayed at a time, this visually adds more, without fetching additional
		setIsAllLoaded(postCount + 6 === POSTLIMIT);
		setPostCount(postCount + 6);
		// } else {
		// 	// If there are more than 18 total posts, this will fetch 6 more at a time from Prismic.
		// 	setPostCount(postCount + 6);
		// 	setCurrentPage(currentPage + 1);
		// }
	}

	return (
		<div className={`
			${styles.posts}
			${addPadding ? styles.paddingTop : ""}
			${primary.background_color ? styles[`background${primary.background_color}`] : ""}
		`}>
			<Container>
				<BasePadding>
					{primary.eyebrow &&
						<div className={styles.eyebrow}>
							<Eyebrow eyebrow={primary.eyebrow} />
						</div>
					}
					<div className={styles.postContainer}>
						{postsData?.slice(0, postCount).map((post, index) => (
							<div key={index} className={primary.display_featured_post ? styles.featuredPost : styles.post}>
								{postType === "page" ? (
									<>
										{post.data?.hero_background?.url &&
											<div className={styles.image}>
												<Link to={`/${post.uid}`}>
													<img src={post.data.hero_background.url} alt={post.data.hero_background.alt} />
												</Link>
											</div>
										}
									</>
								) : (
									<>
										{post.data?.post_thumbnail?.url &&
											<div className={styles.image}>
												<Link to={`/thought-leadership/${post.uid}`}>
													<img src={post.data.post_thumbnail.url} alt={post.data.post_thumbnail.alt} />
												</Link>
											</div>
										}
									</>
								)}
								<div className={styles.postEyebrow}>
									<Eyebrow eyebrow={moment(post?.data?.post_date ? post?.data?.post_date : post.last_publication_date).format("MMMM D, YYYY")} />
								</div>
								<div className={styles.postTitle}>
									<Link to={postType === "page" ? `/${post.uid}` : `/thought-leadership/${post.uid}`}>
										{postType === "page" ? post.data?.page_title : post.data?.post_title}
									</Link>
								</div>
								{!post.data?.post_thumbnail?.url &&
									<div className={styles.postSummary}>
										<RichText render={post.data?.post_summary} />
									</div>
								}
								<div className={styles.postCta}>
									<Cta>
										<Link to={postType === "page" ? `/${post.uid}` : `/thought-leadership/${post.uid}`}>
											Read More
										</Link>
									</Cta>
								</div>
							</div>
						))}
					</div>
					{isBlogIndex && !allLoaded &&
						<div className={styles.cta}>
							<Cta type="button" align="center" hideArrow={true} color={primary.background_color}>
								<button onClick={() => displayMore()}>
									Load more
								</button>
							</Cta>
						</div>
					}
					{/* {isBlogIndex && allLoaded &&
						<div className={styles.cta}>
							<Cta type="button" align="center" hideArrow={true} color={primary.background_color}>
								<button onClick={() => displayMore()}>
									Load more
								</button>
							</Cta>
						</div>
					} */}
					{!isBlogIndex && primary.cta_link?.slug &&
						<div className={styles.cta}>
							<Cta type="button" color={primary.background_color}>
								<Link to={`/${primary.cta_link.slug}`}>
									{primary.cta_text}
								</Link>
							</Cta>
						</div>
					}
				</BasePadding>
			</Container>
		</div>
	);
};

export default withRouter(Posts);
