import React from "react";
import { InView } from "react-intersection-observer";

import TextModule from "../TextModule";
import MediaColumns from "../MediaColumns";
import LogoSlider from "../LogoSlider";
import Divider from "../Divider";
import Stats from "../Stats";
import Columns from "../Columns";
import Posts from "../Posts";
import PostsArchive from "../PostsArchive";
import Tout from "../Tout";
import FixedImageColumn from "../FixedImageColumn";
import Media from "../Media";
import Carousel from "../Carousel";
import AudioPlayer from "../AudioPlayer";
import TextCarousel from "../TextCarousel";
import Form from "../Form";
import TextTile from "../TextTile";
import FeaturedPosts from "../FeaturedPosts";
import CallToAction from "../CallToAction";
import ContentImageColumn from "../ContentImageColumn";
import LogoSlider1 from "../LogoSlider1";
import ImageColumns from "../ImageColumns";
import YoutubeEmbeds from "../YoutubeEmbeds";
import Team from "../Team";
import GatedContent from "../GatedContent";
import GatedForm from "../GatedForm";

const ComponentComposer = ({ component, index, previousComponent, lastItem, isPost, location }) => {
	const componentList = {
		text_module: TextModule,
		media_columns: MediaColumns,
		logo_slider: LogoSlider,
		divider: Divider,
		stats: Stats,
		columns: Columns,
		posts: Posts,
		tout: Tout,
		fixed_image_column: FixedImageColumn,
		media: Media,
		carousel: Carousel,
		audio_player: AudioPlayer,
		text_carousel: TextCarousel,
		form: Form,
		text_tiles: TextTile,
		featured_posts: FeaturedPosts,
		call_to_action: CallToAction,
		posts_archive: PostsArchive,
		image_content_block: ContentImageColumn,
		logo_slider_1_row: LogoSlider1,
		image_columns: ImageColumns,
		youtube_embeds: YoutubeEmbeds,
		team_columns: Team,
		gated_content: GatedContent,
		gated_form: GatedForm,
	};

	const currentBackgroundColor = component?.primary.background_color;
	const previousBackgroundColor = previousComponent?.primary.background_color;

	const ComponentType = componentList[component.slice_type]

	const addPadding = (index === 0 && !isPost) || (currentBackgroundColor?.toLowerCase() !== previousBackgroundColor?.toLowerCase());

	const componentInView = background => {
		// console.log('background', background);
	};

	const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
	if (ComponentType) {
		return (
			<InView rootMargin={`${(vh / 2.1) * -1}px`} onChange={inView => inView && componentInView(component.primary.background_color)}>
				<ComponentType
					addPadding={addPadding}
					content={component}
					isPost={isPost}
					lastItem={lastItem}
					componentIndex={index}
					location={location}
				/>
			</InView>
		)
	}
	else {
		return (
			<div data-component="component-error"></div>
		)
	}
};

export default ComponentComposer;
