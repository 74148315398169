// - Name
// - Title
// - Email
// - Company
// - Note  (Short text)

import React from "react";
import styles from "./Form.module.scss";
import Cta from "../Cta";
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

const BrandSafety = props => (
	<form action="https://usebasin.com/f/9ca57b53335d" method="POST">
		<Stack spacing={1.5} mb={1} className={styles.inputWrapper}>
			<TextField type="text" id="name" name="name" label="NAME" required="required" />
			<TextField type="text" id="company" name="company" label="COMPANY" required="required" />
			<TextField type="text" id="title" name="title" label="JOB TITLE" required="required" />
			<TextField type="email" id="email" name="email" label="EMAIL ADDRESS" required="required" />
			<TextField type="text" id="note" name="note" label="NOTE" required="required" />
			<Cta type="button" color={props.background_color}>
				<input type="submit" />
			</Cta>
		</Stack>
	</form>
);

export default BrandSafety;
