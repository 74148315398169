import React from "react";
import { Link } from "react-router-dom";

import styles from "./CallToAction.module.scss";
import Container from "../Container";
import BasePadding from "../BasePadding";
import Cta from "../Cta";

const CallToAction = ({ addPadding, isPost, content: { primary }}) => (
	<div className={`
		${styles.callToAction}
		${addPadding ? styles.paddingTop : ""}
		${primary.background_color ? styles[`background${primary.background_color}`] : ""}
	`}>
		<Container>
			<BasePadding>
				{primary.cta_link?.slug &&
					<div className={styles.cta}>
						<Cta type="button" color={primary.background_color}>
							<Link to={`/${primary.cta_link.slug}`}>
								{primary.cta_text}
							</Link>
						</Cta>
					</div>
				}
				{primary.cta_link?.url &&
					<div className={styles.cta}>
						<Cta type="button" color={primary.background_color}>
							<a href={primary.cta_link.url}>
								{primary.cta_text}
							</a>
						</Cta>
					</div>
				}
			</BasePadding>
		</Container>
	</div>
);

export default CallToAction;