import React, { useState, useEffect, useRef } from "react";
import { RichText } from "prismic-reactjs";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";

import Container from "../Container";
import BasePadding from "../BasePadding";
import Eyebrow from "../Eyebrow";

import styles from "./Carousel.module.scss";

const Carousel = ({ addPadding, content: { primary, items }}) => {
	const sliderImg = useRef();
	const [currentSlide, setCurrentSlide] = useState(0);
	const [sliderRef, slider] = useKeenSlider({
		initial: 0,
		loop: true,
		centered: false,
		slidesPerView: 1,
		spacing: 10,
		mode: "snap",
		autoHeight: true,
		slideChanged(s) {
			setCurrentSlide(s.details().relativeSlide);
		},
		breakpoints: {
			"(min-width: 40rem)": {
				slidesPerView: 1.5,
				centered: true
			},
			"(min-width: 75rem)": {
				slidesPerView: 1.5,
				centered: true
			},
		}
	});

	useEffect(() => {
		if (sliderImg.current && slider) {
			sliderImg.current.onload = (() => {
				slider.resize();
			})
		}
	}, [sliderImg, slider]);

	return (
		items[0]?.media?.url ?
			<div className={`
				${styles.carousel}
				${addPadding ? styles.paddingTop : ""}
				${primary.background_color ? styles[`background${primary.background_color}`] : ""}
			`}>
				<Container>
					<>
						{primary.eyebrow &&
							<div className={styles[`eyebrow${primary.eyebrow_alignment}`]}>
								<Eyebrow eyebrow={primary.eyebrow} />
							</div>
						}
						<div className={styles.carouselContainer} ref={sliderRef}>
							{items.map((item, index) => (
								<div key={index} className={`${currentSlide === index ? styles.activeSlide : styles.slide} keen-slider__slide`}>
									{item.media.kind === "image" ?
										<img ref={sliderImg} src={item.media.url} alt={primary.media?.alt} />
										:
										<video ref={sliderImg} controls>
											<source src={item.media.url} type="video/mp4" />
										</video>
									}
									<BasePadding>
										<div className={styles.text}>
											<RichText render={item.text} />
										</div>
									</BasePadding>
								</div>
							))}
						</div>
						{slider && (
							<div className={styles.dots}>
							{[...Array(slider.details().size).keys()].map(index => (
									<button
										key={index}
										onClick={() => {
											slider.moveToSlideRelative(index);
										}}
										className={styles[`${(currentSlide === index ? "dotActive" : "dot")}`]}
									/>
								)
							)}
							</div>
						)}
					</>
				</Container>
			</div>
		:
		null
	);
};

export default Carousel;
