import React, { useState, useEffect, useRef } from "react";
import { RichText } from "prismic-reactjs";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";

import styles from "./TextCarousel.module.scss";
import Eyebrow from "../Eyebrow";
import Container from "../Container";
import BasePadding from "../BasePadding";

const TextCarousel = ({ addPadding, content: { primary, items }}) => {
	const [sliderHeight, setSliderHeight] = useState(0);
	const [pause, setPause] = useState(false);
	const timer = useRef();
	const sliderImg = useRef();
	const [currentSlide, setCurrentSlide] = useState(0);
	const [sliderRef, slider] = useKeenSlider({
		initial: 0,
		loop: true,
		slidesPerView: 1,
		mode: "snap",
		autoHeight: true,
		duration: 1000,
		slideChanged(s) {
			setCurrentSlide(s.details().relativeSlide);
		},
		dragStart: () => {
			setPause(true);
		},
		dragEnd: () => {
			setPause(false);
		}
	});

	useEffect(() => {
		if (sliderRef?.current) {
			sliderRef.current.addEventListener("mouseover", () => {
				setPause(true);
			});
			sliderRef.current.addEventListener("mouseout", () => {
				setPause(false);
			});
		}
	}, [sliderRef]);

	useEffect(() => {
		timer.current = setInterval(() => {
			if (!pause && slider) {
				slider.next();
			}
		}, 6000);
		return () => {
			clearInterval(timer.current);
		};
	}, [pause, slider]);

	useEffect(() => {
		if (sliderImg.current && slider) {
			sliderImg.current.onload = (() => {
				slider.resize();
			})
		}
	}, [sliderImg, slider]);

	return (
		items[0]?.text_item.length ?
			<div className={`
				${styles.textCarousel}
				${addPadding ? styles.paddingTop : ""}
				${primary.background_color ? styles[`background${primary.background_color}`] : ""}
			`}>
				<Container>
					<BasePadding>
						<>
							<div className={styles.eyebrow}>
								<Eyebrow eyebrow={primary.eyebrow} />
							</div>
							<div className={styles.carouselContainer} ref={sliderRef} >
								{items.map((item, index) => (
									<div key={index} className={`${styles.slide} keen-slider__slide`}>
										{item?.background?.url && <img ref={sliderImg} src={item.background.url} className={styles.backgroundImage} />}
										<div className={item?.background?.url ? styles.withImage : ""}>
											<RichText render={item.text_item} />
										</div>
									</div>
								))}
							</div>
							{slider && (
							<div className={styles.dots}>
								{[...Array(slider.details().size).keys()].map(index => (
									<button
										key={index}
										onClick={() => {
											slider.moveToSlideRelative(index);
										}}
										className={styles[`${(currentSlide === index ? "dotActive" : "dot")}`]}
									/>
								)
								)}
							</div>
						)}
						</>
					</BasePadding>
				</Container>
			</div>
		:
		null
	)
};

export default TextCarousel;