import React from "react";
import { RichText } from "prismic-reactjs";
import { Link } from "react-router-dom";

import Eyebrow from "../Eyebrow";
import BasePadding from "../BasePadding";
import Container from "../Container";
import Cta from "../Cta";

import styles from "./ContentImageColumn.module.scss";

const fileType = file => { return file ? file.match(/\.[0-9a-z]+$/i) : null };

const FixedImageColumn = ({ addPadding, content: { primary }}) => {
	const mediaFileType = fileType(primary.media?.name);

	return (
		<div className={`
			${styles.fixedImageColumn}

			${primary.image_alignment ? styles.imageAlignRight : styles.imageAlignLeft}
			${primary.background_color ? styles[`background${primary.background_color}`] : ""}
		`}>


					<>

						<div className={styles.fixedContainer}>
							<div className={styles.textContainer}>
								<div className={styles.eyebrow}>
									<Eyebrow eyebrow={primary.eyebrow} />
								</div>
								<div className={styles.text}>
									<RichText render={primary.copy} />
									{primary.cta_link1?.slug &&
										<div className={styles.cta}>
											<Cta type="button" color={primary.background_color}>
												<Link to={`/${primary.cta_link1.slug}`}>
													{primary.cta_text1}
												</Link>
											</Cta>
										</div>
									}
									{primary.cta_link1?.url &&
										<div className={styles.cta}>
											<Cta type="button" color={primary.background_color}>
												<a href={primary.cta_link1.url}>
													{primary.cta_text1}
												</a>
											</Cta>
										</div>
									}
								</div>

							</div>

							<div className={styles.imageContainer}>
								{mediaFileType && mediaFileType[0] === ".mp4"
									?
										<video autoPlay muted loop playsInline>
											<source src={primary.media.url} type="video/mp4" />
										</video>
									:
										<img src={primary.media.url} alt={primary.media.alt} />
								}

							</div>
						</div>
					</>


		</div>
	);
};
export default FixedImageColumn;
