import React, { useRef, useEffect } from "react";

const SvgSquiggle = () => {
	const xArray = Array.from({length: 400}, (value, key) => {return key});
	let t = 0;
	const svgRef = useRef();

	useEffect(() => {
		const squiggleLine = () => {
			let points = xArray.map(x => {
				let y = 4 + 2.5 * Math.sin((x + t) / 2.75);
	
				return [x, y];
			});
			let path = "M" + points.map(p => {
				return `${p[0]},${p[1]}`;
			}).join(" L");
	
			if (svgRef?.current) {
				svgRef.current.setAttribute("d", path);
			}
	
			t += 0.25;
	
			requestAnimationFrame(squiggleLine);
		};
		squiggleLine();
		if (svgRef.current === null) {
			cancelAnimationFrame(squiggleLine);
		}
	}, [svgRef]);
	
	return (
		<svg className="squiggle">
			<path ref={svgRef} d="M0,4 L200,4" />
		</svg>
	);
}

export default SvgSquiggle;