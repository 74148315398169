import React, { useEffect } from "react";
import {
	BrowserRouter,
	Route,
	Switch
} from "react-router-dom";
import ReactGA from 'react-ga4';
// import { createBrowserHistory } from "history";

import Navigation from "./components/Navigation";
import Footer from "./components/Footer";

import Page from "./containers/Page";
import Blog from "./containers/Blog";

/**
 * Main application component
 */

const App = () => {
  ReactGA.initialize([
	{
		trackingId: "GTM-ML6G243",
	},
	{
	  trackingId: "355553449",
	}
  ]);
  
  return (
    <>
		<BrowserRouter>
			<>
				<Navigation />
				<main>
					<Switch>
						<Route path="/thought-leadership/:uid(.*)" component={Blog} />
						<Route path="/:uid(.*)" component={Page} />
					</Switch>
				</main>
				<Footer />
			</>
      </BrowserRouter>
    </>
  )
}

export default App
