import React, { useEffect, useState } from "react";
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { CaretDownIcon } from '@radix-ui/react-icons';
import { Link, useLocation } from "react-router-dom";
import OxfordLogo from "./resources/oxford-road.png";
import { client } from "../../prismic-configuration";

import BasePadding from "../BasePadding";
import Container from "../Container";

import styles from "./Navigation.module.scss";
import SvgSquiggle from "../SvgSquiggle";

import "./dropdownstyles.scss";

const Navigation = () => {
	const [navigationData, setNavigationData] = useState(null);
	const [menuOpen, setMenuOpen] = useState(false);
	const location = useLocation();

	useEffect(() => {
		const fetchData = async () => {
			const result = await client.getSingle("navigation");
			setNavigationData(result.data);
		}
		fetchData();
	}, []);

	const toggleMenu = () => {
		setMenuOpen(!menuOpen);
	};

	const closeMenu = () => {
		setMenuOpen(false);
	};

	return (
		<header className={styles.header}>
			<Container>
				<BasePadding>
				<div style={{ display: 'flex'}}>
					<Link to="/" className={styles.logo}><img src={OxfordLogo} alt="Oxford Road" /></Link>
					<button className={menuOpen ? styles.hamburgerOpen : styles.hamburger} onClick={() => toggleMenu()}>
						menu
					</button>
				<NavigationMenu.Root className={styles.navigation}>
					<NavigationMenu.List className={menuOpen ? styles.menuOpen : styles.menu}>
						{navigationData
							?
							(
								navigationData.links.map((linkItem, index) => {
									const hasDropdown = linkItem.link_title.toLowerCase() === 'thinking';
									const isActive = location.pathname.replace("/", "") === linkItem.link.uid;

									return (
										<NavigationMenu.Item key={index}>
											{hasDropdown ? (
												<>
													<NavigationMenu.Trigger>
														<Link to={`/${linkItem.link.uid}`} className={isActive ? styles.activeLink : ""}>
															<div className={styles.linkItem}>
																<span>
																	{linkItem.link_title}
																</span>
																<CaretDownIcon className="CaretDown" aria-hidden />
															</div>
															<SvgSquiggle />
														</Link>
													</NavigationMenu.Trigger>
													<NavigationMenu.Content className="DropdownMenu">
														<ul className="secondaryMenu">
															<li className="secondaryLink">
																<Link to="/thought-leadership#industry-thought-pieces">
																	Industry Thought Pieces
																</Link>
															</li>
															<li className="secondaryLink">
																<Link to="/thought-leadership#the-influencer">
																	The Influencer
																</Link>
															</li>
															<li className="secondaryLink">
																<Link to="/thought-leadership#the-media-roundtable">
																	The Media Roundtable
																</Link>
															</li>
															<li className="secondaryLink">
																<Link to="/thought-leadership#oxford-road-youtube">
																	Our Youtube
																</Link>
															</li>
														</ul>
													</NavigationMenu.Content>
												</>
											) : (
												<Link className={isActive ? styles.activeLink : ""} to={`/${linkItem.link.uid}`} onClick={() => closeMenu()}>
													<div className={styles.linkItem}>
														<span>
															{linkItem.link_title}
														</span>
													</div>
													<SvgSquiggle />
												</Link>
											)}
										</NavigationMenu.Item>
									)
								})
							)
							:
							(
								Array.from({length: 5}).map((_, index) => <li key={index} className={styles.loading}></li>)
							)
							
						}
						<NavigationMenu.Indicator className="NavigationMenuIndicator">
							<div className="Arrow" />
						</NavigationMenu.Indicator>
					</NavigationMenu.List>
					<div className="ViewportPosition">
						<NavigationMenu.Viewport className="NavigationMenuViewport" />
					</div>
				</NavigationMenu.Root>

					{/* <nav className={styles.navigation}>
						<Link to="/" className={styles.logo}><img src={OxfordLogo} alt="Oxford Road" /></Link>
						<button className={menuOpen ? styles.hamburgerOpen : styles.hamburger} onClick={() => toggleMenu()}>
							menu
						</button>
						<ul className={menuOpen ? styles.menuOpen : styles.menu}>
							{navigationData
								?
								(
									navigationData.links.map((linkItem, index) => {
										const isActive = location.pathname.replace("/", "") === linkItem.link.uid;

										return (
											<li key={index}>
												{linkItem?.link?.uid 
													?
														<Link className={isActive ? styles.activeLink : ""} to={`/${linkItem.link.uid}`} onClick={() => closeMenu()}>
															{linkItem.link_title}
															<SvgSquiggle />
														</Link>
													:
														<span>{linkItem.link_title}</span>
												}
											</li>
										)
									})
								)
								:
								(
									Array.from({length: 5}).map((_, index) => <li key={index} className={styles.loading}></li>)
								)
							
						}
						</ul>
					</nav> */}
				</div>
				</BasePadding>
			</Container>
		</header>
	);
}

export default Navigation;