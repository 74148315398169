import React from "react";

import { ReactComponent as MutedIcon } from "./resources/muted.svg";
import { ReactComponent as PlayIcon } from "./resources/play.svg";
import { ReactComponent as PauseSmall } from "./resources/pause-small.svg";
import { ReactComponent as PlaySmall } from "./resources/play-small.svg";
import styles from "./PlayButton.module.scss";

const PlayButton = ({ active, play, stop, icon, backgroundColor }) => (
	icon ?
	<button className={`${styles.icon} ${styles[`background${backgroundColor}`]} ${active ? styles.pauseIcon : styles.playIcon}`} onClick={() => active ? stop() : play()}>
		{active ? <PauseSmall className={styles.playing} /> : <PlaySmall />}
	</button>
	:
	<button className={`${active ? styles.pauseIcon : styles.playIcon}`} onClick={() => active ? stop() : play()}>
		<span className={styles.text}>Audio {active ? "On" : "Off"}</span><span className={styles.icon}>{active ? <PlayIcon className={styles.playing} /> : <MutedIcon />}</span>
	</button>
);

export default PlayButton;