import React, { useState, useEffect, forwardRef } from "react";
import { useLocation } from "react-router";
import * as Select from '@radix-ui/react-select';
import { ChevronDownIcon, CheckIcon } from "@radix-ui/react-icons";

import Audiolytics from "./Audiolytics";
import BasePadding from "../BasePadding";
import BrandSafety from "./BrandSafety";
import Container from "../Container";
import General from "./General";
import Media from "./Media";
import Press from "./Press";
import Lead from "./Lead";
import Jobs from "./Jobs";
import Newsletter from "./Newsletter";

import styles from "./Form.module.scss";
import "./selectstyles.scss";

const SelectItem = forwardRef(({ children, className, ...props }, forwardedRef) => {
	return (
	  <Select.Item className="SelectItem" {...props} ref={forwardedRef}>
		<Select.ItemText>{children}</Select.ItemText>
		<Select.ItemIndicator className="SelectItemIndicator">
		  <CheckIcon />
		</Select.ItemIndicator>
	  </Select.Item>
	);
  });

const Form = ({ addPadding, content: { primary }}) => {
	const location = useLocation();

	const [currentForm, setCurrentForm] = useState(
		location?.hash ? location.hash.replace("#", "") : "lead"
	);
	const formList = {
		"audiolytics": Audiolytics,
		"contact": General,
		"media": Media,
		"press": Press,
		"lead": Lead,
		"jobs": Jobs,
		"newsletter": Newsletter,
		"brandsafety": BrandSafety,
		"Contact Form": General,
		"Audiolytics": Audiolytics,
		"General Contact": General,
		"Media": Media,
		"Press": Press,
		"Lead": Lead,
		"Jobs": Jobs,
		"Newsletter Signup": Newsletter,
		"Brand Safety": BrandSafety,
	}

	const formPicker = primary?.allow_user_to_select_form;

	const FormComponent = formList[primary.form_type || "contact"];
	const StatefulFormComponent = formList[currentForm];

	useEffect(() => {
		if (Boolean(location.hash)) {
			document.getElementById('contact-form').scrollIntoView();
		}
	}, [location.hash]);

	return (
		<div
		id="contact-form"
		style={{ scrollMarginTop: '75px' }}
		className={`
			${styles.form}
			${addPadding ? styles.paddingTop : ""}
			${primary.background_color ? styles[`background${primary.background_color}`] : ""}
		`}>
			<Container>
				<BasePadding>
					{formPicker ? (
						<>
							<div className={styles.selectForm} id="select">
								<div className={styles.input}>
									<label htmlFor="select-form">What are you interested in?</label>
									<Select.Root defaultValue={currentForm} onValueChange={item => setCurrentForm(item)}>
										<Select.Trigger className="SelectTrigger">
											<Select.Value />
											<Select.Icon className="SelectIcon">
												<ChevronDownIcon />
											</Select.Icon>
										</Select.Trigger>
										<Select.Portal className="Portal">
											<Select.Content zIndex="1" className="SelectContent">
												<Select.Viewport className="SelectViewport">
													<SelectItem value="lead">Become a Client</SelectItem>
													<SelectItem value="newsletter">Subscribe to our newsletter</SelectItem>
													<SelectItem value="press">Press Inquiries</SelectItem>
													<SelectItem value="media">I Have a Podcast</SelectItem>
													<SelectItem value="jobs">Careers</SelectItem>
													<SelectItem value="brandsafety">Brand Safety</SelectItem>										
													<SelectItem value="audiolytics">Audiolytics™ Report</SelectItem>										
													<SelectItem value="contact">Other</SelectItem>
												</Select.Viewport>
											</Select.Content>
										</Select.Portal>
									</Select.Root>
								</div>
							</div>
							<StatefulFormComponent {...primary} />
						</>
					)
					: (
						<FormComponent {...primary} />
					)}
				</BasePadding>
			</Container>
		</div>
	);
};
export default Form;