import React from "react";
import styles from "./Cta.module.scss";

const Cta = ({ type, align, hideArrow, color, children }) => {
	const classes = `
		${type in styles ? `${styles[type]} ` : ""}
		${align ? `${styles[`align${align}`]}` : ""}
		${hideArrow ? `${styles.hideArrow}` : ""}
		${type === "button" ? `${styles[`color${color}`]} ` : ""}
	`;

	return (
		<div className={`${styles.cta} ${classes}`}>
			{children}
		</div>
	);
}

export default Cta;