import React, { useRef, useState, useEffect } from "react";
import { RichText } from "prismic-reactjs";
import { Link } from "react-router-dom";

import Container from "../Container";
import BasePadding from "../BasePadding";
import Cta from "../Cta";
import Eyebrow from "../Eyebrow";

import styles from "./TextTile.module.scss";

const TextTile = ({ addPadding, content: { primary, items }}) => {
	const [ displaySlides, setDisplaySlides ] = useState([]);
	const [ isTransitioning, setIsTransitioning ] = useState(0);
	const timer = useRef();

	useEffect(() => {
		const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
		setDisplaySlides(items.slice(0, vw < 1000 ? 3 : 6));
	}, [items]);

	useEffect(() => {
		timer.current = setInterval(() => {
			const displayArray = [...displaySlides];
			const itemsNotInActiveArray = items.filter(item => !displayArray.includes(item));
			const randomNewItem = itemsNotInActiveArray[Math.floor(Math.random() * itemsNotInActiveArray.length)];
			const randomReplaceItem = Math.floor(Math.random() * displayArray.length);
			displayArray.splice(randomReplaceItem, 1, randomNewItem);
			setIsTransitioning(randomReplaceItem);
			setTimeout(() => {
				setIsTransitioning(null);
			}, 750);
			setDisplaySlides(displayArray);
		}, 2500);
		return () => {
			clearInterval(timer.current);
		};
	}, [displaySlides]);

	return (
		<div className={`
			${styles.textTile}
			${addPadding ? styles.paddingTop : ""}
			${primary.background_color ? styles[`background${primary.background_color}`] : ""}
		`}>
			<Container>
				<BasePadding>
					<div className={`${styles.content} ${primary.text1.length ? styles.hasText : styles.tilesOnly}`}>
						<div className={styles.copy}>
							{primary?.eyebrow  && <div className={styles.eyebrow}><Eyebrow eyebrow={primary.eyebrow} /></div>}
							<div className={styles.text}>
								<RichText render={primary.text1} />
								{primary.cta_link?.slug &&
									<div className={styles.footer}>
										<Cta type="button" color={primary.background_color}>
											<Link to={`/${primary.cta_link.slug}`}>{primary.cta_text}</Link>
										</Cta>
									</div>
								}
								{primary.cta_link?.url &&
									<div className={styles.footer}>
										<Cta type="button" color={primary.background_color}>
											<a href={primary.cta_link.url}>{primary.cta_text}</a>
										</Cta>
									</div>
								}
							</div>
						</div>
						<div className={styles.tiles}>
							{displaySlides.map((item, index) => (
								<div key={index} className={`${styles.textItem} ${isTransitioning === index ? styles.transitionIn : ""}`}>
									<RichText render={item.text_item} />
								</div>
							))}
						</div>
					</div>
				</BasePadding>
			</Container>
		</div>
	);
};

export default TextTile;