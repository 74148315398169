import React from "react";
import { RichText } from "prismic-reactjs";

import BasePadding from "../BasePadding";
import Container from "../Container";
import Cta from "../Cta";
import Eyebrow from "../Eyebrow";

import styles from "./TextModule.module.scss";
import { Link } from "react-router-dom";

const TextModule = ({ addPadding, isPost, content: { primary }}) => (
	primary.text ?
		<div
			id={primary.section_id ? primary.section_id : primary.text[0]?.text.toLowerCase().replace(/\W/g,'-')}
			style={{ scrollMarginTop: '75px' }}
			className={`
				${styles.textModule}
				${isPost ? styles.blogPost : ""}
				${addPadding ? styles.paddingTop : ""}
				${primary.background_color ? styles[`background${primary.background_color}`] : ""}
				${styles[`alignment${primary.text_alignment}`]}
		`}>
			<Container>
				<BasePadding>
					<>
						{primary?.eyebrow && <div className={styles.eyebrow}><Eyebrow eyebrow={primary.eyebrow[0]?.text} /></div>}
						<div className={styles.text}>
							<RichText render={primary.text} />
						</div>
						{primary.cta_link?.slug &&
							<div className={styles.cta}>
								<Cta type="button" color={primary.background_color}>
									<Link to={`/${primary.cta_link.slug}`}>
										{primary.cta_text}
									</Link>
								</Cta>
							</div>
						}
						{primary.cta_link?.url &&
							<div className={styles.cta}>
								<Cta type="button" color={primary.background_color}>
									<a href={primary.cta_link.url}>
										{primary.cta_text}
									</a>
								</Cta>
							</div>
						}
					</>
				</BasePadding>
			</Container>
		</div>
	:
	null
);

export default TextModule;