import React from "react";

import styles from "./BasePadding.module.scss";

const BasePadding = ({ children }) => (
	<div className={`${styles.basePadding} basePadding`}>
		{children}
	</div>
);

export default BasePadding;