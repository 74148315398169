// - Name
// - Title
// - Email
// - Company
// - Note  (Short text)

import React from "react";
import styles from "./Form.module.scss";
import Cta from "../Cta";
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const autoCompleteOptions = [
	"The Influencer",
	"Media Roundtable Podcast",
	"Other Podcast",
	"Linkedin (Post)",
	"Online article",
	"Newsletter",
	"Google or other search engine",
	"From a network partner",
	"I am a media vendor",
	"Another client referred me",
	"I worked at a previous client of Oxford Road",
	"Referral from Oxford Road team member",
	"Google (Ad)",
	"Linkedin (Ad)",
	"Friend/Colleague referral",
	"News/press",
	"Another ad agency"
];

const Lead = props => (
	<form action="https://usebasin.com/f/22e8b3fa4640" method="POST">
		<Stack spacing={1.5} mb={1} className={styles.inputWrapper}>
			<TextField type="text" id="name" name="name" label="NAME" required="required" />
			<TextField type="text" id="company" name="company" label="COMPANY" required="required" />
			<TextField type="text" id="title" name="title" label="JOB TITLE" required="required" />
			<TextField type="email" id="email" name="email" label="EMAIL ADDRESS" required="required" />
			<Autocomplete
				id="how-did-you-hear-about-us"
				name="how-did-you-hear-about-us"
				required
				openOnFocus={false}
				freeSolo
				options={autoCompleteOptions.map(option => option)}
				renderInput={(params) => <TextField {...params} label="HOW DID YOU HEAR ABOUT US?" name="how-did-you-hear-about-us" />}
			/>
			<TextField type="text" id="note" name="note" label="NOTE" required="required" />
			<Cta type="button" color={props.background_color}>
				<input type="submit" />
			</Cta>
		</Stack>
	</form>
);

export default Lead;
