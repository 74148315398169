import React from "react";

import styles from "./Divider.module.scss";
import Container from "../Container";
import BasePadding from "../BasePadding";

const Divider = ({ addPadding, isPost, content: { primary }}) => (
	<div className={`
		${styles.divider}
		${isPost ? styles.blogPost : ""}
		${addPadding ? styles.paddingTop : ""}
		${primary.background_color ? styles[`background${primary.background_color}`] : ""}
	`}>
		{primary.width ?
			<hr />
			:
			<Container>
				<BasePadding>
					<hr />
				</BasePadding>
			</Container>
		}
	</div>
);

export default Divider;